'use client' // Required because of the `useEffect` usage and compatibility with the app router

import { useState, useMemo, useEffect, useCallback } from 'react'
import Cookies from 'js-cookie'
import { useHotkeys } from 'react-hotkeys-hook'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline'

import ColorModeContext from '@/providers/context/ColorContext'
import { createLightTheme, createDarkTheme } from '@/styles/theme'

const ThemeProvider = ({ children }) => {
  const systemTheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'
  const cookieTheme = Cookies.get('settings.theme')

  const [mode, setMode] = useState(cookieTheme || null)
  const [isMounted, setIsMounted] = useState(false)

  const toggleColorMode = useCallback(() => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
  }, [])

  useEffect(() => {
    Cookies.set('settings.theme', mode, { expires: 365 })
  }, [mode])

  useEffect(() => {
    if (isMounted && !mode) {
      setMode(systemTheme)
    }
  }, [systemTheme, isMounted, mode])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useHotkeys('ctrl+m', toggleColorMode)

  const theme = useMemo(() => (mode === 'light' ? createLightTheme() : createDarkTheme()), [mode])

  const colorMode = useMemo(() => ({ toggleColorMode }), [toggleColorMode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MUIThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline enableColorScheme />
        {children}
      </MUIThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default ThemeProvider
