import { createTheme } from '@mui/material/styles'
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  amber,
  orange,
  deepOrange
} from '@mui/material/colors'

import { Inter, JetBrains_Mono } from 'next/font/google'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}

const inter = Inter({
  subsets: ['latin'],
  display: 'swap'
})

const jetBrainsMono = JetBrains_Mono({
  subsets: ['latin'],
  display: 'swap'
})

export const colors = [
  red[500],
  pink[500],
  purple[500],
  deepPurple[500],
  indigo[500],
  lightBlue[500],
  cyan[500],
  teal[500],
  lightGreen[500],
  green[500],
  lime[500],
  amber[500],
  orange[500],
  deepOrange[500]
]

const base = createTheme({
  typography: {
    fontFamily: inter.style.fontFamily,
    fontWeightRegular: 300
  },
  monospace: {
    fontFamily: jetBrainsMono.style.fontFamily
  },
  content: {
    height: 'calc(95vh - 65px)',
    heightWithToolbar: 'calc(100vh - 150px)'
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiFilledInput: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiListItem: {
      defaultProps: {
        dense: true
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiFab: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiTable: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontFamily: jetBrainsMono.style.fontFamily
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense'
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 1
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2160
    }
  }
})

// Create a theme instance.
export const createLightTheme = () =>
  createTheme({
    ...base,
    palette: {
      mode: 'light',
      contrastThreshold: 4.5,
      background: {
        default: '#f5f5f5'
      }
    }
  })

export const createDarkTheme = () =>
  createTheme({
    ...base,
    palette: {
      mode: 'dark',
      contrastThreshold: 4.5,
      background: {
        default: '#272727'
      }
    }
  })

// Adding custom theme properties
declare module '@mui/material/styles' {
  interface Theme {
    monospace?: {
      fontFamily?: string
    }
    content: {
      height: string
      heightWithToolbar?: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    monospace?: {
      fontFamily?: string
    }
    content?: {
      height?: string
      heightWithToolbar?: string
    }
  }
}
