// Note: for now, this file is only used for the auth/signin page.
// This setup is meant to not leak unnecessary information to the client (contexts, sidebar contents, etc.)
// This layout should only contain code that is necessary for the auth/signin page and doesn't expose any admin tool functional information

'use client' // Required because of the providers using contexts

import { SessionProvider } from 'next-auth/react'
import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter'
import ThemeProvider from '@/providers/ThemeProvider'

export default function AppRouterLayout({ children }) {
  return (
    <html lang='en'>
      <body>
        <AppRouterCacheProvider>
          <ThemeProvider>
            <SessionProvider>{children}</SessionProvider>
          </ThemeProvider>
        </AppRouterCacheProvider>
      </body>
    </html>
  )
}
